<template>
  <div>
    <div class="pulsating-circle" />
    <div class="button" @click="dialog = true">
      <v-icon style="opacity: 1" color="primary" size="20px">
        mdi-information-variant
      </v-icon>
    </div>
    <div class="text-center">
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Informação
            <v-icon style="opacity: 1" color="primary" size="30px">
              mdi-information-outline
            </v-icon>
          </v-card-title>
          <v-card-text>
            {{ msgModal }}
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> 
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    msgModal: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped>
.pulsating-circle,
.button {
  position: absolute;
  left: 93%;
  top: 41%;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

.pulsating-circle {
  animation: pulse 1.2s ease infinite;
  background: #550899;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99;
  border: none;
  background: #fff;
  background-size: 18px;
  cursor: pointer;
  outline: none;
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
</style>